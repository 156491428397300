import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableHead, TableRow, Grid } from '@material-ui/core'
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'

import Pagination from '@helper/Pagination/Pagination';
import TableLoader from '@helper/Table/components/TableLoader';

import {
  openModalController,
  loadDataAccountsController,
  closeGridController,
  updateListAccountController
} from '@components/AccountComponent/controller';

import {
  getGridById,
} from '@root/controller'

import RowBody from './RowBody'

import { withRouter } from "react-router-dom";
import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'

import {
  PROVIDER_ACCOUNT_NAME,
  PROVIDER_ACCOUNT_ID,
  INTERNAL_ACCOUNT_ID,
  LAST_SYNC_TIMESTAMP,
  LAST_TRANSACTION_TIMESTAMP,
  INTERNAL_ACCOUNT_TYPE,
  TOKEN_TYPE,
  ACCOUNT_LIST_GRID,
  BALANCE,
  MARKETPLACE
} from '@components/AccountComponent/constants'

import { toggleSort } from '@root/helperFunctions'

import { useStyles } from '@components/AccountComponent/styles'

const TableAccount = ({ loadDataAccounts, items, closeGrid, history: { push }, isLoading, countItems, updateListAccount,
  initialValues: { fieldSort, typeSort, top, skip } }) => {

  const classes = useStyles();
  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })
  const [valueStringFilter, setValueStringFilter] = useState(null)

  const [currentKey, setKey] = useState(null);

  const onChangePanel = key => {
    setKey(currentKey === key ? null : key)
  }

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true`

  const handleClickHead = e => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort') && th.getAttribute('data-sort') === 'true') {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateListAccount(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${top}&$skip=${skip}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`)
      setPagination({ top, skip })
    }
  }

  const handleChangePagination = (e, page) => {
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateListAccount(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true${valueStringFilter ? '&$filter=' + valueStringFilter : ''}`)
  }

  useEffect(() => {
    setPagination({ top, skip })
  }, [countItems])

  useEffect(() => {
    const valueStringFilter = addFilter('Enabled', null, 'Enabled');
    let loadDataQuery = startQuery;
    if (valueStringFilter) {
      loadDataQuery += '&$filter=' + valueStringFilter;
    }

    loadDataAccounts(loadDataQuery, push);
    return () => {
      closeGrid({ gridId: ACCOUNT_LIST_GRID });
    }
  }, [ACCOUNT_LIST_GRID])

  const [uf, setUf] = useState('')

  const addFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var value = objFilters[key].value;

      if ((!value && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (!objFilters[key].type) {
        arr.push(`${value}`);
        continue;
      }

      if (typeof value === 'string' || value instanceof String) {
        value = "'" + value.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${value})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${value}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    setValueStringFilter(doneStringFilter);
    return doneStringFilter;
  };

  const updateFilter = (value, filter, filed) => {
    const doneStringFilter = addFilter(value, filter, filed);

    updateListAccount(
      `?$orderby=${sort.field} ${sort.type}&$top=${top}&$skip=${skip}&$count=true`
      + `${doneStringFilter ? `&$filter=${encodeURIComponent(doneStringFilter)}` : ''}`)
    setPagination({ top, skip })
  }

  const configHeadTitle = [
    {
      width: 2, titleColumn: 'Status',
      titleColumnSort: 'Enabled',
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, null, 'Enabled')} options={[
        { label: 'Enabled', value: 'Enabled' },
        { label: 'Disabled', value: 'Enabled eq false' },
        { label: 'All', value: '' },
      ]} />
    },
    {
      titleColumnSort: MARKETPLACE,
      isSort: true,
      currentSortField: sort.field,
      width: 8,
      titleColumn: 'Marketplace',
      sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, MARKETPLACE)} options={[
        { label: 'All', value: '' },
        { label: 'Etsy', value: 'Etsy' },
        { label: 'Shopify (Restricted)', value: 'Shopify' },
        { label: 'Shopify (Full)', value: 'ShopifyFull' },
        { label: 'eBay', value: 'Ebay' }
      ]} />
    },
    {
      titleColumnSort: PROVIDER_ACCOUNT_NAME,
      isSort: true,
      currentSortField: sort.field,
      width: 15,
      titleColumn: 'Shop Name',
      sortType: sort.type
    },
    {
      titleColumnSort: PROVIDER_ACCOUNT_ID,
      isSort: true,
      currentSortField: sort.field,
      width: 15,
      titleColumn: 'Shop ID',
      sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, PROVIDER_ACCOUNT_ID)} filterString="string" />
    },
    {
      titleColumnSort: INTERNAL_ACCOUNT_ID,
      isSort: true,
      currentSortField: sort.field,
      width: 10,
      titleColumn: 'WB client id',
      sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, INTERNAL_ACCOUNT_ID)} filterString="number" />
    },
    {
      titleColumnSort: LAST_SYNC_TIMESTAMP,
      isSort: true,
      currentSortField: sort.field,
      width: 10,
      titleColumn: 'Last sync date',
       sortType: sort.type
    },
    {
      titleColumnSort: LAST_TRANSACTION_TIMESTAMP,
      isSort: true,
      currentSortField: sort.field,
      width: 10,
      titleColumn: 'Last transaction date',
       sortType: sort.type
    },
    {
      titleColumnSort: INTERNAL_ACCOUNT_TYPE,
      isSort: true,
      currentSortField: sort.field,
      width: 10,
      titleColumn: 'Type (WB or KOMOK)', sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, INTERNAL_ACCOUNT_TYPE)} options={[
        { label: 'All', value: '' },
        { label: 'WB', value: 'WB' },
        { label: 'KOMOK', value: 'KOMOK' }
      ]} />
    },
    {
      titleColumnSort: TOKEN_TYPE,
      isSort: true,
      currentSortField: sort.field,
      width: 10, titleColumn: 'Token type', sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, TOKEN_TYPE)} options={[
        { label: 'All', value: '' },
        { label: 'Temp', value: 'Temp' },
        { label: 'Permanent', value: 'Permanent' }
      ]} />
    },
    {
      titleColumnSort: BALANCE,
      isSort: true,
      currentSortField: sort.field,
      width: 10, titleColumn: 'Balance', sortType: sort.type
    },
    { width: 2, titleColumn: '' },

  ];

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.hoverHead} onClick={handleClickHead}>
                {
                  configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                    return (
                      <HeadStyledTableCell
                        key={titleColumn}
                        titleColumnSort={titleColumnSort}
                        isSort={isSort}
                        currentSortField={currentSortField}
                        width={width}
                        percent={true}
                        widthPx={true}
                        titleColumn={titleColumn}
                        sortType={sortType}
                      />
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <tr>
                {
                  configHeadTitle.map(({ titleColumn, filterComponent = null, customComponent = null }) => <td key={titleColumn}>
                    {customComponent ? customComponent : (
                      filterComponent ? filterComponent : null
                    )}
                  </td>)
                }
              </tr>
              {
                isLoading ? (
                  <tr>
                    <td colSpan={10}>
                      <Box py={5}><TableLoader /></Box>
                    </td>
                  </tr>
                ) : (
                  items.length ? (
                    items.map(data => <RowBody key={data.Id} {...data} currentKey={currentKey} onChangePanel={onChangePanel} />)
                  ) : <tr><td colSpan={10} style={{ textAlign: 'center' }}>No data</td></tr>
                )
              }
            </TableBody>
          </Table>
        </Grid>
        <Box pt={1}>
          <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
        </Box>
      </Grid>
    </Box>
  );
}

TableAccount.propTypes = {
  loadDataAccounts: PropTypes.func.isRequired,
  closeGrid: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  countItems: PropTypes.number,
  isLoading: PropTypes.bool,
  updateListAccount: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired
};

TableAccount.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
};

const enhance = compose(

  connect(state => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: ACCOUNT_LIST_GRID })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    openModal: openModalController,
    loadDataAccounts: loadDataAccountsController,
    closeGrid: closeGridController,
    updateListAccount: updateListAccountController
  }),
  withRouter
)

export default enhance(TableAccount)
