import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  bodyItem: {
    padding: "5px !important",
    textAlign: "left",
    "& p": {
      fontSize: 10,
    },
    border: "none !important",
  },
  bodyRow: {
    transition: "all .3s",
    "&:hover": {
      background: "#f0f0f3",
    },
    justifyContent: "space-around",
    border: "1px solid black",
    overflow: "auto",
    maxHeight: "140px",
    whiteSpace: "normal",
  },
}));

const SubTwoTableItem = (props) => {
  const {
    Id,
    Paid,
    Status,
    CurrencyCode,
    AmountGross,
    handleCheck,
    TransactionId,
    PaymentId,
    OrderId,
    ReceiptId,
    checkboxes,
    ProviderAccountName,
    CustomerEmail,
    CustomerName,
    Title,
    UploadRetries,
    UploadAttempt,
    Error,
  } = props;
  const classes = useStyles();
  return (
    <tr
      className={classes.bodyRow}
      style={{
        background: Status === 'Failed' ? (UploadAttempt >= UploadRetries ? "#edb7b7" : "#ffd9ae") : 'none' ,
      }}
    >
      <td className={classes.bodyItem}>
        <Grid item style={{  textAlign: "center" }}>
          {(Status === "New" || Status === "Failed") && (
            <Checkbox
              style={{ padding: 0 }}
              color="primary"
              checked={checkboxes?.[Id] || false}
              inputProps={{ "aria-label": "secondary checkbox" }}
              onChange={(e) => handleCheck(e, Id)}
            />
          )}
        </Grid>
      </td>
      <td className={classes.bodyItem} style={{ minWidth: "100px" }}>
        <Grid item>
          <Typography variant="body2">
            {moment(Paid).format("YYYY-MM-DD HH:mm:ss Z")}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{Status}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{TransactionId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{PaymentId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{OrderId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{ReceiptId}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography
            variant="body2"
            style={{
              textAlign: "left",
              color: Number(AmountGross) > 0 ? "green" : "red",
            }}
          >
            {AmountGross + " " + CurrencyCode}{" "}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{ProviderAccountName}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{CustomerEmail}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item>
          <Typography variant="body2">{CustomerName}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem} style={{ minWidth: "150px" }}>
        <Grid item>
          <Typography variant="body2">{Title}</Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem}>
        <Grid item style={{ textAlign: "center" }}>
          <Typography variant="body2">
            {UploadAttempt} of {UploadRetries}
          </Typography>
        </Grid>
      </td>
      <td className={classes.bodyItem} style={{ minWidth: "100px" }}>
        <Grid item>
          <Typography variant="body2">{Error}</Typography>
        </Grid>
      </td>
    </tr>
  );
};

SubTwoTableItem.propTypes = {
  Id: PropTypes.string,
  Paid: PropTypes.string,
  Status: PropTypes.string,
  CurrencyCode: PropTypes.string,
  AmountGross: PropTypes.number,
  handleCheck: PropTypes.func.isRequired,
};

SubTwoTableItem.defaultProps = {
  Id: null,
  Paid: null,
  Status: null,
  CurrencyCode: null,
  AmountGross: null,
  checkboxes: [],
};

export default SubTwoTableItem;
