import React, { useEffect, useState } from 'react';
import { loadTableAllCurrentSyncController, updateTableAllCurrentSyncController } from '../controller'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { closeGridController, getGridById } from '@root/controller'
import { toggleSort } from '@root/helperFunctions'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'
import CheckAll from '@helper/CheckAll/CheckAll'
import { Grid, Box, Button } from '@material-ui/core'
import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'
import { useStyles } from '@components/AccountComponent/styles';
import Pagination from '@helper/Pagination/Pagination';
import TableLoader from '@helper/Table/components/TableLoader';
import SubTwoTableItem from '@components/AccountComponent/components/TableAccount/subComponents/SubTwoTableItem';
import SendIcon from '@material-ui/icons/Send';
import PropTypes from 'prop-types'
import { PushTransactionsController, IgnoreTransactionsController } from '@components/AccountComponent/controller'

const TableAllCurrentSyncBoard = ({
  loadTableAllCurrentSync,
  closeGrid,
  internalAccountId,
  marketplace,
  accountId,
  updateTableAllCurrentSync,
  items,
  countItems,
  initialValues: { fieldSort, typeSort, top, skip },
  isLoading,
  PushTransactions
}) => {
  const classes = useStyles()

  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })
  const [valueStringFilter, setValueStringFilter] = useState(null)

  const [displayBtnForceWB, setDisplayBtnForceWB] = useState(true)

  const [isAllChecked, setIsAllChecked] = useState(false)
  const [checkboxes, setCheckboxes] = useState(null)
  const handleCheck = (e, id) => {
    setCheckboxes({ ...checkboxes, [id]: e.currentTarget.checked })
  }

  const uncheckedAll = () => {
    setCheckboxes(null)
    setIsAllChecked(false)
  }

  const checkedAll = () => {
    const allCheckbox = items.reduce((acc, item) => {
      acc[item.Id] = !isAllChecked
      return acc
    }, {})

    setCheckboxes(allCheckbox)
    setIsAllChecked(!isAllChecked)
  }

  const updateTable = (query) => {
    uncheckedAll();
    updateTableAllCurrentSync(query);
  };

  const handlePushTransactions = () => {
    PushTransactions(Object.entries(checkboxes).filter(el => el[1]).map(el => el[0]), 'loadTableAllCurrentSync', startQuery)
    uncheckedAll();
  }

  useEffect(() => {
    if (checkboxes && Object.entries(checkboxes).filter(el => el[1]).length > 0) {
      setDisplayBtnForceWB(false)
    } else {
      setDisplayBtnForceWB(true)
    }
  }, [checkboxes])

  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true&$filter=((accountId eq null and internalAccountId eq ${internalAccountId} and marketplace eq '${marketplace}') or (accountId eq ${accountId}))`

  const handleClickHead = e => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort') && th.getAttribute('data-sort') === 'true') {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateTable(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${top}&$skip=${skip}&$count=true&$filter=((accountId eq null and internalAccountId eq ${internalAccountId} and marketplace eq '${marketplace}') or (accountId eq ${accountId})) ${valueStringFilter ? ' and ' + encodeURIComponent(valueStringFilter) : ''}`)
      setPagination({ top, skip })
    }
  }

  const handleChangePagination = (e, page) => {
    setCheckboxes(null)
    setIsAllChecked(false)
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top });

    updateTable(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true&$filter=((accountId eq null and internalAccountId eq ${internalAccountId} and marketplace eq '${marketplace}') or (accountId eq ${accountId})) ${valueStringFilter ? ' and ' + encodeURIComponent(valueStringFilter) : ''}`)
  }

  const [uf, setUf] = useState('')
  const updateFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var value = objFilters[key].value;

      if ((!value && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (!objFilters[key].type) {
        arr.push(`${value}`);
        continue;
      }

      if (typeof value === 'string' || value instanceof String) {
        value = "'" + value.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${value})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${value}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    setValueStringFilter(doneStringFilter)
    updateTable(
      `?$orderby=${sort.field} ${sort.type}&$top=${top}&$skip=${skip}&$count=true`
      + `&$filter=((accountId eq null and internalAccountId eq ${internalAccountId} and marketplace eq '${marketplace}') or (accountId eq ${accountId})) ${doneStringFilter ? ` and ${encodeURIComponent(doneStringFilter)}` : ''}`)
    setPagination({ top, skip })
  }

  const configHeadTitle = [
    {
      width: 2, titleColumn: 'CheckAll',
      customComponent: <CheckAll checkedAll={checkedAll} isAllChecked={isAllChecked} />
    },
    {
      width: 10, titleColumn: 'Paid',
      titleColumnSort: 'Paid', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      titleColumnSort: 'Status', isSort: true, currentSortField: sort.field, width: 5, titleColumn: 'Status', sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, filter, 'Status')} options={[
        { label: 'All', value: '' },
        { label: 'New', value: 'New' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Completed', value: 'Completed' },
        { label: 'Failed', value: 'Failed' },
        { label: 'Ignored', value: 'Ignored' }
      ]} />
    },
    {
      width: 5, titleColumn: 'Transaction Id',
      titleColumnSort: 'TransactionId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'TransactionId')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Payment Id',
      titleColumnSort: 'PaymentId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'PaymentId')} filterString="string" />
    },
    {
      width: 10, titleColumn: 'Order Id',
      titleColumnSort: 'OrderId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'OrderId')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Receipt Id',
      titleColumnSort: 'ReceiptId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ReceiptId')} filterString="number" />
    },
    {
      width: 5, titleColumn: 'Amount Gross',
      titleColumnSort: 'AmountGross', isSort: true, currentSortField: sort.field, sortType: sort.type,
    },
    {
      width: 5, titleColumn: 'Provider Account Name',
      titleColumnSort: 'ProviderAccountName', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ProviderAccountName')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Customer Email',
      titleColumnSort: 'CustomerEmail', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'CustomerEmail')} filterString="string" />
    },
    {
      width: 7, titleColumn: 'Customer Name',
      titleColumnSort: 'CustomerName', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 20, titleColumn: 'Title',
      titleColumnSort: 'Title', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 5, titleColumn: 'Upload Attempt',
      titleColumnSort: 'UploadAttempt', isSort: true, currentSortField: sort.field, sortType: sort.type,
      
    },
    {
      width: 10, titleColumn: 'Error',
      titleColumnSort: 'Error', isSort: true, currentSortField: sort.field, sortType: sort.type
    }
  ];

  useEffect(() => {
    loadTableAllCurrentSync(startQuery)
    return () => {
      closeGrid({ gridId: `loadTableAllCurrentSync` })
    }
  }, [])

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.hoverHead} onClick={handleClickHead}>
                {
                  configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                    return (
                      <HeadStyledTableCell
                        key={titleColumn}
                        titleColumnSort={titleColumnSort}
                        isSort={isSort}
                        currentSortField={currentSortField}
                        width={width}
                        widthPx={true}
                        percent={true}
                        titleColumn={titleColumn}
                        sortType={sortType}
                      />
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <tr>
                {
                  configHeadTitle.map(({ titleColumn, filterComponent = null, customComponent = null }) => <td key={titleColumn}>
                    {customComponent ? customComponent : (
                      filterComponent ? filterComponent : null
                    )}
                  </td>)
                }
              </tr>
              {
                isLoading ? (
                  <tr>
                    <td colSpan={14}>
                      <Box py={5}><TableLoader /></Box>
                    </td>
                  </tr>
                ) : (
                  items.length ? (
                    items.map(data => <SubTwoTableItem key={data.Id} {...data} handleCheck={handleCheck} checkboxes={checkboxes} />)
                  ) : <tr><td colSpan={14} style={{ textAlign: 'center' }}>No data</td></tr>
                )
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Box pl={4} py={3}>
                <Button variant="contained" size="small" color="primary" startIcon={<SendIcon />} onClick={handlePushTransactions} disabled={displayBtnForceWB}>
                  Deposit transactions to user account in WB
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box pt={1}>
          <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
        </Box>
      </Grid>
    </Box>
  )
}

TableAllCurrentSyncBoard.propTypes = {
  loadTableAllCurrentSync: PropTypes.func.isRequired,
  updateTableAllCurrentSync: PropTypes.func.isRequired,
  PushTransactions: PropTypes.func.isRequired,
}

TableAllCurrentSyncBoard.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
  initialValues: {
    fieldSort: 'Paid',
    typeSort: 'desc',
    top: 20,
    skip: 0
  }
}

const enhance = compose(
  connect((state) => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: `loadTableAllCurrentSync` })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    loadTableAllCurrentSync: loadTableAllCurrentSyncController,
    closeGrid: closeGridController,
    updateTableAllCurrentSync: updateTableAllCurrentSyncController,
    PushTransactions: PushTransactionsController,
    IgnoreTransactions: IgnoreTransactionsController
  })
)

export default enhance(TableAllCurrentSyncBoard)
