import React, { useContext, useState } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import TextField from '@helper/reduxForm/TextField/TextField'
import SelectField from '@helper/reduxForm/SelectField/SelectField'

import { dispatch } from '@store/store'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types'

import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'

import { editAccountController } from '../controller'
import { PopupContext } from '@context/PopupContext';
import { Validate, Normalize } from '@helper/Validate';

import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getGridById } from '@root/controller'
import * as moment from 'moment'

const CLOSE_MODAL = 'CLOSE_MODAL';

const EditAccountComponentModal = ({ modalProps: { id, marketplace }, editAccount, currentValues, formSyncErrors }) => {
  const { openPopup } = useContext(PopupContext)

  const isDisabledButton = !!(formSyncErrors && Object.keys(formSyncErrors).length);

  const [showKeyString, setShowKeyString] = useState(false);
  const handleClickShowKeyString = () => {
    setShowKeyString(!showKeyString);
  };

  const [showKeyShared, setShowKeyShared] = useState(false);
  const handleClickShowKeyShared = () => {
    setShowKeyShared(!showKeyShared);
  };

  const save = () => {
    if (currentValues.LastSyncTimestamp) {
      currentValues.LastSyncTimestamp = moment(currentValues.LastSyncTimestamp)
        .utc()
        .format();
    }
    editAccount(openPopup, id, marketplace, currentValues);
  };

  const closeModal = () => {
    dispatch({ type: CLOSE_MODAL });
  }

  return (
    <div>
      <Typography variant={'h4'}>Edit Account</Typography>
      <Box p={2}>
        <form>
          <Box pb={1}>
            <Box pb={1}>
              <TextField name="ProviderAccountId" label="Original Name" variant="outlined" fullWidth
                inputProps={{
                  readOnly: true
                }}
              />
            </Box>
            <TextField name="ProviderAccountName" label="New Shop Name" variant="outlined" fullWidth />
          </Box>
          <Box pb={1}>
            <SelectField
              name="Marketplace"
              label="Marketplace"
              variant="outlined"
              options={[
                { label: 'Etsy', value: 'Etsy' },
                { label: 'Shopify (Restricted)', value: 'Shopify' },
                { label: 'Shopify (Full)', value: 'ShopifyFull' },
                { label: 'eBay', value: 'Ebay' }
               ]}
              validate={[Validate.required]}
              fullWidth />
          </Box>
          <Box pb={1}>
            <TextField name="InternalAccountId" label="WB client id" variant="outlined" fullWidth validate={[Validate.required, Validate.positiveNumber]} />
          </Box>
          <Box pb={1}>
            <SelectField
              name="InternalAccountType"
              label="Type (WB or KOMOK)"
              variant="outlined"
              options={[{ label: 'WB', value: 'WB' }, { label: 'KOMOK', value: 'KOMOK' }]}
              validate={[Validate.required]}
              fullWidth />
          </Box>
          <Box pb={1}>
            <TextField name="MinUploadRetryTimeout" label="Minimum load repeat timeout, seconds" variant="outlined" fullWidth normalize={Normalize.number} validate={[Validate.required, Validate.positiveNumber]} />
          </Box>
          <Box pb={1}>
            <TextField name="UploadRetries" label="Upload Retries" variant="outlined" fullWidth value="WB" normalize={Normalize.number} validate={[Validate.required, Validate.positiveNumber]} />
          </Box>
          <Box pb={1}>
            <TextField name="LastSyncTimestamp" type="datetime-local"
              label="Last sync date" variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box pb={1}>
            <TextField
              name="Key1" label="KEYSTRING" variant="outlined" fullWidth validate={[Validate.required]} normalize={Normalize.trim}
              type={showKeyString ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowKeyString}
                      edge="end"
                    >
                      {showKeyString ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pb={1}>
            <TextField name="Key2" label="SHARED SECRET" variant="outlined" fullWidth validate={[Validate.required]} normalize={Normalize.trim}
              type={showKeyShared ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowKeyShared}
                      edge="end"
                    >
                      {showKeyShared ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box pt={2} display="flex" justifyContent="center">
            <Box pr={2}><Button variant="contained" color="primary" startIcon={<AddCircleIcon />} onClick={save} disabled={isDisabledButton}>Save</Button></Box>
            <Box><Button variant="contained" color="secondary" onClick={closeModal}>Close</Button></Box>
          </Box>
        </form>
      </Box>
    </div>
  )
}

EditAccountComponentModal.propTypes = {
  editAccount: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(),
}
EditAccountComponentModal.defaultProps = {
  modalProps: {}
}

const enhance = compose(
  connect(state => {
    const { items } = getGridById(state, { gridId: 'ACCOUNT_LIST_GRID' })
    const filterItems = items.filter(({ Id }) => Id === state.modal.props.id)
    const { 
      ProviderAccountName, 
      ProviderAccountId, 
      InternalAccountId, 
      InternalAccountType, 
      Key1, 
      Key2, 
      UploadRetries,
      MinUploadRetryTimeout,
      LastSyncTimestamp,
      Marketplace} = filterItems[0]

    return {
      currentValues: getFormValues('EDIT_ACCOUNT_FORM')(state),
      formSyncErrors: getFormSyncErrors('EDIT_ACCOUNT_FORM')(state),
      modalProps: state.modal.props,
      initialValues: {
        ProviderAccountName: ProviderAccountName,
        ProviderAccountId: ProviderAccountId,
        InternalAccountId: InternalAccountId,
        InternalAccountType: InternalAccountType,
        UploadRetries: UploadRetries,
        MinUploadRetryTimeout: MinUploadRetryTimeout,
        Key1: Key1,
        Key2: Key2,
        Marketplace: Marketplace,
        LastSyncTimestamp: LastSyncTimestamp ? moment(LastSyncTimestamp).format('YYYY-MM-DD[T]HH:mm') : null
      }
    }
  }, {
    editAccount: editAccountController
  }),
  reduxForm({
    form: 'EDIT_ACCOUNT_FORM',
    enableReinitialize: true,
  }),
)

export default enhance(EditAccountComponentModal)