import React, { useState, useEffect, useRef } from 'react'
import { TextField, Box, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { compose } from 'redux';

import { dispatch } from '@store/store'

const useStyles = makeStyles(() => ({
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    width: 175,
    '& li': {
      '&[data-select-filter=true]': {
        color: '#3f51b5'
      },
      transition: 'all .3s',
      '&:hover': {
        cursor: 'pointer',
        color: '#3f51b5'
      }
    }
  },
  searchInput:{
    padding: '0 10px', minWidth: '25px;' 
  }
}))

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


const SearchTextField = ({ updateFilter, filterString, reset }) => {
  const [filter, setFilter] = useState(filterString === 'number' ? 'eq' : 'contains')
  const [openFilter, setOpenFilter] = useState(false)
  const [text, setText] = useState('')
  const classes = useStyles()

  const prevFilter = usePrevious(filter);

  const handleOpenFilter = () => {
    setOpenFilter(openFilter ? false : true)
  }

  const handleSetFilter = filter => {
    setFilter(filter)
    setOpenFilter(false)
  }

  const handleResetFilter = () => {
    setFilter(filterString === 'number' ? 'eq' : 'contains')
    setOpenFilter(false)
    setText('')
    updateFilter('', filterString === 'number' ? 'eq' : 'contains')
  }

  const handleChange = e => {
    var value =  e.target.value.trim();
    value = filterString === 'number' ? Number(value) : value;

    setText(value)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSearch = () => {
    updateFilter(text, filter)
  }

  useEffect(() => {
    if (prevFilter && prevFilter !== filter) {
      updateFilter(text, filter)
    }
  }, [filter])


  useEffect(() => {
    if (reset) {
      handleResetFilter()
      dispatch({ type: 'RESET_FILTER', payload: false });
    }
  }, [reset])

  const handleClick = e => {
    if (e.target.closest(".btn-tooltip") === null && e.target.closest(".tooltip") === null) {
      setOpenFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  return (
    <Box display="flex" px={1} alignItems="center">
      <IconButton color="primary" aria-label="cancel" component="span" onClick={handleSearch} style={{ padding: 0 }}>
        <SearchIcon />
      </IconButton>
      <TextField
        fullWidth
        value={text}
        onChange={e => handleChange(e)}
        onKeyDown={handleKeyDown}
        className={classes.searchInput}
      />
      {
        filterString === 'number' && (
          <Box width={30} position="relative" className="btn-tooltip">
            <IconButton color="primary" aria-label="search" component="span" onClick={handleOpenFilter} style={{ padding: 0 }}>
              <TuneIcon />
            </IconButton>
            {
              openFilter && (
                <Box
                  className="tooltip"
                  style={{
                    position: 'absolute',
                    top: 40,
                    left: -140,
                    boxShadow: '0 0 10px',
                    borderRadius: 3,
                    background: 'white',
                    zIndex: 10,
                    padding: 10
                  }}
                >
                  <ul className={classes.ul}
                  >
                    <li onClick={() => handleSetFilter('eq')} data-select-filter={filter === 'eq'}>Equals</li>
                    <li onClick={() => handleSetFilter('ne')} data-select-filter={filter === 'ne'}>Does not equal</li>
                    <li onClick={() => handleSetFilter('gt')} data-select-filter={filter === 'gt'}>Greater than</li>
                    <li onClick={() => handleSetFilter('ge')} data-select-filter={filter === 'ge'}>Greater than or equal to</li>
                    <li onClick={() => handleSetFilter('lt')} data-select-filter={filter === 'lt'}>Less than</li>
                    <li onClick={() => handleSetFilter('le')} data-select-filter={filter === 'le'}>Less than or equal to</li>
                  </ul>
                </Box>
              )
            }
          </Box>
        )
      }

      <Box width={30}>
        {
          filter && (
            <IconButton color="primary" aria-label="cancel" component="span" onClick={handleResetFilter} style={{ padding: 0 }}>
              <CancelIcon />
            </IconButton>
          )
        }
      </Box>
    </Box>
  )
}

SearchTextField.propTypes = {
  updateFilter: PropTypes.func,
  filterString: PropTypes.string.isRequired,
  reset: PropTypes.bool.isRequired
}

SearchTextField.defaultProps = {
  updateFilter: () => { }
}

const enhance = compose(
  connect(state => {
    const { reset } = state.resetFilter;

    return {
      reset
    }
  })
)

export default enhance(SearchTextField)
