import React, { useEffect, useState } from 'react';
import { loadTableFailedTransactionsController, updateTableFailedTransactionsController } from '../controller'
import { connect } from 'react-redux';
import { compose } from 'redux'
import { closeGridController, getGridById } from '@root/controller'
import { toggleSort } from '@root/helperFunctions'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import SearchTextField from '@helper/SearchTextField/SearchTextField'
import SearchSelect from '@helper/SearchSelect/SearchSelect'
import CheckAll from '@helper/CheckAll/CheckAll'
import { Grid, Box, Button, Typography } from '@material-ui/core'
import HeadStyledTableCell from '@components/AccountComponent/components/TableAccount/Template/HeadStyledTableCell'
import { useStyles } from '@components/AccountComponent/styles';
import Pagination from '@helper/Pagination/Pagination';
import TableLoader from '@helper/Table/components/TableLoader';
import TableFailedTransactionsTableItem from './TableFailedTransactionsTableItem';
import SendIcon from '@material-ui/icons/Send';
import Refresh from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types'
import { PushTransactionsController, IgnoreTransactionsController } from '@components/AccountComponent/controller'

const TableFailedTransactions = ({
  loadTableFailedTransactions,
  updateTableFailedTransactions,
  closeGrid,
  items,
  countItems,
  initialValues: { fieldSort, typeSort, top, skip },
  isLoading,
  PushTransactions,
  IgnoreTransactions
}) => {
  const classes = useStyles()

  const [sort, setSort] = useState({ field: fieldSort, type: typeSort })
  const [pagination, setPagination] = useState({ top: top, skip: skip })
  const [valueStringFilter, setValueStringFilter] = useState(null)
  const [queryString, setQueryString] = useState(null)

  const [transactionsSelected, setSelectedTransactions] = useState(true)

  const [isAllChecked, setIsAllChecked] = useState(false)
  const [checkboxes, setCheckboxes] = useState(null)

  const updateTable = (query) => {
    uncheckedAll();
    setQueryString(query);
    updateTableFailedTransactions(query);
  };

  const loadTable = (query) => {
    setQueryString(query);
    loadTableFailedTransactions(query);
  };

  const handleCheck = (e, id) => {
    setCheckboxes({ ...checkboxes, [id]: e.currentTarget.checked })
  }

  const checkedAll = () => {
    const allCheckbox = items.reduce((acc, item) => {
      acc[item.Id] = !isAllChecked
      return acc
    }, {})

    setCheckboxes(allCheckbox)
    setIsAllChecked(!isAllChecked)
  }

  const uncheckedAll = () => {
    setCheckboxes(null)
    setIsAllChecked(false)
  }

  const handlePushTransactions = () => {
    PushTransactions(Object.entries(checkboxes).filter(el => el[1]).map(el => el[0]), 'TableFailedTransactions', queryString);
    uncheckedAll();
  }

  const handleIgnoreTransactions = () => {
    IgnoreTransactions(Object.entries(checkboxes).filter(el => el[1]).map(el => el[0]), 'TableFailedTransactions', queryString)
  }

  const refreshTable = () => {
    updateTable(queryString);
  }

  useEffect(() => {
    if (checkboxes && Object.entries(checkboxes).filter(el => el[1]).length > 0) {
      setSelectedTransactions(false)
    } else {
      setSelectedTransactions(true)
    }
  }, [checkboxes])

  const filterFailedTransactions = "(Status eq 'Failed' or Status eq 'Pending' and UploadAttempt ne 0)";
  const startQuery = `?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.skip}&$count=true&$filter=${filterFailedTransactions}`

  const handleClickHead = e => {
    const th = e.target.closest('th')
    if (th.hasAttribute('data-sort') && th.getAttribute('data-sort') === 'true') {
      setSort({ field: th.getAttribute('data-head-name'), type: toggleSort(sort.type) })
      updateTable(`?$orderby=${th.getAttribute('data-head-name')} ${toggleSort(sort.type)}&$top=${top}&$skip=${skip}&$count=true&$filter=${filterFailedTransactions}${valueStringFilter ? ' and ' + valueStringFilter : ''}`)
      setPagination({ top, skip })
    }
  }

  const handleChangePagination = (e, page) => {
    setCheckboxes(null)
    setIsAllChecked(false)
    setPagination({ ...pagination, skip: pagination.top * page - pagination.top })
    updateTable(`?$orderby=${sort.field} ${sort.type}&$top=${pagination.top}&$skip=${pagination.top * page - pagination.top}&$count=true&$filter=${filterFailedTransactions}${valueStringFilter ? ' and ' + valueStringFilter : ''}`)
  }

  const [uf, setUf] = useState('')
  const updateFilter = (value, filter, filed) => {
    setUf(state => ({ ...state, [filed]: { type: filter, value: value } }))

    const objFilters = { ...uf, [filed]: { type: filter, value: value } }
    let arr = [];
    for (const key in objFilters) {
      var value = objFilters[key].value;

      if ((!value && objFilters[key].value !== 0)
        || objFilters[key].value === `'All'`) {
        continue
      }

      if (!objFilters[key].type) {
        arr.push(`${value}`);
        continue;
      }

      if (typeof value === 'string' || value instanceof String) {
        value = "'" + value.replace(/^'+|'+$/gi, '') + "'";
      }

      if (objFilters[key].type === 'contains') {
        arr.push(`contains(${key}, ${value})`)
      } else {
        arr.push(`${key} ${objFilters[key].type} ${value}`)
      }
    }

    const doneStringFilter = arr.join(' and ')
    setValueStringFilter(doneStringFilter)
    let querystring = `?$orderby=${sort.field} ${sort.type}&$top=${top}&$skip=${skip}&$count=true`
      + `&$filter=${filterFailedTransactions}${doneStringFilter ? ` and ${encodeURIComponent(doneStringFilter)}` : ''}`;

    updateTable(querystring);
    setPagination({ top, skip });
  }

  const configHeadTitle = [
    {
      width: 2, titleColumn: 'Check All',
      customComponent: <CheckAll checkedAll={checkedAll} isAllChecked={isAllChecked} />
    },
    {
      width: 10, titleColumn: 'Paid',
      titleColumnSort: 'Paid', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 5, titleColumn: 'WB client id',
      titleColumnSort: 'InternalAccountId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'InternalAccountId')} filterString="number" />
    },
    {
      width: 3, titleColumn: 'Status',
      titleColumnSort: 'Status', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 11, titleColumn: 'Transaction Id',
      titleColumnSort: 'TransactionId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'TransactionId')} filterString="string" />
    },
    {
      width: 4, titleColumn: 'Payment Id',
      titleColumnSort: 'PaymentId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'PaymentId')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Order Id',
      titleColumnSort: 'OrderId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'OrderId')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Receipt Id',
      titleColumnSort: 'ReceiptId', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ReceiptId')} filterString="number" />
    },
    {
      width: 5, titleColumn: 'Amount Gross',
      titleColumnSort: 'AmountGross', isSort: true, currentSortField: sort.field, sortType: sort.type
    },
    {
      width: 11, titleColumn: 'Provider Account Name',
      titleColumnSort: 'ProviderAccountName', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'ProviderAccountName')} filterString="string" />
    },
    {
      width: 6, titleColumn: 'Customer Email',
      titleColumnSort: 'CustomerEmail', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'CustomerEmail')} filterString="string" />
    },
    {
      width: 5, titleColumn: 'Customer Name',
      titleColumnSort: 'CustomerName', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchTextField updateFilter={(text, filter) => updateFilter(text, filter, 'CustomerName')} filterString="string" />
    },
    {
      width: 13, titleColumn: 'Title',
      titleColumnSort: 'Title', isSort: true, currentSortField: sort.field, sortType: sort.type
    },

    {
      width: 5, titleColumn: 'Upload Attempt',
      titleColumnSort: 'UploadAttempt', isSort: true, currentSortField: sort.field, sortType: sort.type,
      filterComponent: <SearchSelect updateFilter={(text, filter) => updateFilter(text, null, 'UploadAttempt')} options={[
        { label: 'All', value: '' },
        { label: 'Failed', value: 'UploadAttempt ge UploadRetries' },
        { label: 'In process', value: 'UploadAttempt lt UploadRetries' }
      ]} />
    },
    {
      width: 10, titleColumn: 'Error',
      titleColumnSort: 'Error', isSort: true, currentSortField: sort.field, sortType: sort.type
    }
  ];

  useEffect(() => {
    loadTable(startQuery);
    return () => {
      closeGrid({ gridId: `TableFailedTransactions` })
    }
  }, [])

  return (
    <Box pt={2}>
      <Box pb={1} display="flex">
        <Box pr={1}>
          <Button variant={'outlined'} color="primary" onClick={() => refreshTable()} startIcon={<Refresh />}>
            <Typography>Refresh table</Typography>
          </Button>
        </Box>
      </Box>
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.hoverHead} onClick={handleClickHead}>
                {
                  configHeadTitle.map(({ titleColumnSort, isSort, currentSortField, width, titleColumn, sortType }) => {
                    return (
                      <HeadStyledTableCell
                        key={titleColumn}
                        titleColumnSort={titleColumnSort}
                        isSort={isSort}
                        currentSortField={currentSortField}
                        width={width}
                        widthPx={true}
                        percent={true}
                        titleColumn={titleColumn}
                        sortType={sortType}
                      />
                    )
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody>
              <tr>
                {
                  configHeadTitle.map(({ titleColumn, filterComponent = null, customComponent = null }) =>
                    <td key={titleColumn}>
                      {customComponent ? customComponent : (filterComponent ? filterComponent : null)}
                    </td>
                  )
                }
              </tr>
              {
                isLoading ? (
                  <tr>
                    <td colSpan={15}>
                      <Box py={5}><TableLoader /></Box>
                    </td>
                  </tr>
                ) : (
                  items.length
                    ? (items.map(data => <TableFailedTransactionsTableItem key={data.Id} {...data} handleCheck={handleCheck} checkboxes={checkboxes} />))
                    : <tr><td colSpan={15} style={{ textAlign: 'center' }}>No data</td></tr>
                )
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box pl={4} py={3}>
                <Button variant="contained" size="small" color="primary" startIcon={<SendIcon />} onClick={handlePushTransactions} disabled={transactionsSelected}>
                  Deposit transactions to user account in WB
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" size="small" color="primary" startIcon={<SendIcon />} onClick={handleIgnoreTransactions} disabled={transactionsSelected}>
                  Hide transactions
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box pt={1}>
          <Pagination top={pagination.top} skip={pagination.skip} totalCount={countItems} onChange={handleChangePagination} />
        </Box>
      </Grid>
    </Box>
  )
}

TableFailedTransactions.propTypes = {
  loadTableFailedTransactions: PropTypes.func.isRequired,
  updateTableFailedTransactions: PropTypes.func.isRequired
}

TableFailedTransactions.defaultProps = {
  items: [],
  countItems: 0,
  isLoading: false,
  initialValues: {
    fieldSort: 'Paid',
    typeSort: 'desc',
    top: 20,
    skip: 0
  }
}

const enhance = compose(
  connect((state) => {
    const { items, countItems, isLoading } = getGridById(state, { gridId: `TableFailedTransactions` })
    return {
      items,
      countItems,
      isLoading
    }
  }, {
    loadTableFailedTransactions: loadTableFailedTransactionsController,
    closeGrid: closeGridController,
    updateTableFailedTransactions: updateTableFailedTransactionsController,
    PushTransactions: PushTransactionsController,
    IgnoreTransactions: IgnoreTransactionsController,
  })
)

export default enhance(TableFailedTransactions)
